import React, { Component } from 'react';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { I18nProvider } from '@lingui/react';
import { connect } from 'react-redux';
import includes from 'lodash/includes';
import axios from 'axios';

import history from './history';
import App from './App';
import Login from './Login';
import Signup from './Signup';
import ErrorPage from './components/ErrorPage';
import PasswordLost from './PasswordLost';
import ResetPassword from './ResetPassword';
import ArticlePublic from './components/ArticlePublic';
import ExternalLoginRedirector from './ExternalLoginRedirector';
import { selectors } from './reducers/userReducer';
import { selectors as appSelectors } from './reducers/appReducer';
import { queueRefresh } from './actions/userActions';
import { supportedLanguages } from './intl-helpers';
import {
    setBaseApiURL,
    setBaseAssetsURL,
    setAssetsThumbURL,
    setAssetsEtimURL,
    getBaseUrlFromLocation,
    getAssetUrlFromLocation,
    setClientTimeout,
    // getBasePathFromLocation,
    setToken,
} from './api';
import {
    hideLoadingSplashScreen,
    changeLanguage,
    setSchedaProdottoLogTimeout,
    setAppParameter,
} from './actions/appActions';
// import AppLoader from './components/utils/AppLoader';

function getLanguageFromBrowser() {
    return window.navigator.language.substring(0, 2);
}

const CloseButton = ({ closeToast }) => (
    <button onClick={closeToast} className="btn btn-clear float-right" />
);

// const refreshToken = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJqdGkiOiJkY2RhYTgxZC1iYzk4LTQ0ZjQtOTZiYy0zOTE0YTVlYWZjYjAiLCJpYXQiOjE2NDAwOTEwNDIsImV4cCI6MTY0MDEyMDQ0Miwic3ViIjoiOTc0MTU5ZGYtNGZkYS00MTcxLWE3NjYtZWNkZjY3ZmE4ZWMzIiwiY2NfdG9rZW5fdHlwZSI6InJlZnJlc2giLCJjY19sb2NhbGUiOiJpdF9JVCJ9.Owg_iLwjdLb3EY79UhPOkT8hdqYIknMI7U3VIRwbDJM';
// const userId = '974159df-4fda-4171-a766-ecdf67fa8ec3';

// http://localhost:3002/dp/login_external?securety=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJqdGkiOiJkY2RhYTgxZC1iYzk4LTQ0ZjQtOTZiYy0zOTE0YTVlYWZjYjAiLCJpYXQiOjE2NDAwOTEwNDIsImV4cCI6MTY0MDEyMDQ0Miwic3ViIjoiOTc0MTU5ZGYtNGZkYS00MTcxLWE3NjYtZWNkZjY3ZmE4ZWMzIiwiY2NfdG9rZW5fdHlwZSI6InJlZnJlc2giLCJjY19sb2NhbGUiOiJpdF9JVCJ9.Owg_iLwjdLb3EY79UhPOkT8hdqYIknMI7U3VIRwbDJM&id=974159df-4fda-4171-a766-ecdf67fa8ec3

export class Root extends Component {
    constructor(props) {
        super(props);

        window.DATAPOOL_i18n_CATALOGS = {};

        this.state = {
            isFetchingParams: true,
            language: props.language,
            catalogs: {},
        };
    }

    async componentDidMount() {
        const { user, dispatch } = this.props;

        let languageToLoad = this.state.language;

        if (user === null) {
            const browserLanguage = getLanguageFromBrowser();

            if (includes(supportedLanguages, browserLanguage)) {
                languageToLoad = browserLanguage;
                dispatch(changeLanguage(browserLanguage));
            } else {
                console.log(
                    `Browser language not supported, sticking with default: "${this.state.language}"`
                );
            }
        }

        // FIXME: Sarebbe più carino farlo insieme alla promessa per ottenere i parametri ma per il momento va bene cosi
        // da valutare prima se tra i parametri ci sarà anche la lingua di default in caso non ci sia alcun utente loggato
        // o se si userà invece sempre una lingua stabilita (es. inglese)
        await this.loadI18nCatalog(languageToLoad);

        // console.warn(this.props);
        if (this.props.user !== null) {
            setToken(user.token);
            dispatch(queueRefresh());
        }

        // TODO: spostare tutto questo in un servizio e validare presenza di parametri obbligatori???
        this.initParams();
    }

    componentDidUpdate(prevProps) {
        if (this.state.isFetchingParams === false) {
            this.props.dispatch(hideLoadingSplashScreen());
        }

        if (this.props.language !== prevProps.language) {
            this.loadI18nCatalog(this.props.language);
        }

        if (this.props.user && prevProps.user === null) {
            this.initParams();
        }
    }

    async initParams() {
        const { dispatch } = this.props;

        axios(`${process.env.PUBLIC_URL}/parameters.json`)
            // Se trovo il file parameters.json provo ad utilizzarlo ma avendo comunque dei fallback pronti
            .then((res) => {
                setBaseApiURL(
                    res.data.apiURL ? res.data.apiURL : getBaseUrlFromLocation(window.location)
                );
                setBaseAssetsURL(
                    res.data.assetsURL ? res.data.assetsURL : getAssetUrlFromLocation()
                );
                setAssetsThumbURL(
                    res.data.assetsThumbURL ? res.data.assetsThumbURL : getAssetUrlFromLocation()
                );
                setAssetsEtimURL(
                    res.data.assetsEtimURL ? res.data.assetsEtimURL : getAssetUrlFromLocation()
                );

                if (res.data.requestsTimeout) {
                    setClientTimeout(res.data.requestsTimeout);
                }

                if (res.data.schedaProdottoLogTimeout) {
                    dispatch(setSchedaProdottoLogTimeout(res.data.schedaProdottoLogTimeout));
                }

                if (res.data.exportsListDaysLimit) {
                    dispatch(
                        setAppParameter('exportsListDaysLimit', res.data.exportsListDaysLimit)
                    );
                }

                if (res.data.showLists) {
                    dispatch(setAppParameter('showLists', res.data.showLists));
                }

                if (res.data.enableExportPanel) {
                    dispatch(setAppParameter('enableExportPanel', res.data.enableExportPanel));
                }

                /*if (res.data.appColor) {
                    window.document.body.style.setProperty('--color-primary', res.data.appColor);
                }*/
            })
            // Se c'è un errore nel fetch utilizzo direttamente i fallback
            .catch(() => {
                setBaseApiURL(getBaseUrlFromLocation(window.location));
                setBaseAssetsURL(getAssetUrlFromLocation());
            })
            .finally(() => {
                // Forzo il redirect solo se mi trovo in un path che non inizia per /dp/
                // const re = /^\/dp\/.*/;

                // if (re.test(window.location.pathname) === false) {
                //     const newPath = this.getNewPath();
                //     navigate(newPath);
                // }

                setTimeout(() => {
                    this.setState({
                        isFetchingParams: false,
                    });
                }, 0);
            });
    }

    async loadI18nCatalog(langCode) {
        const catalog = await import(
            /* webpackMode: "lazy", webpackChunkName: "i18n-[index]" */
            `./locale/${langCode}/messages.js`
        );

        this.setState((state) => ({
            language: langCode,
            catalogs: {
                ...state.catalogs,
                [langCode]: catalog,
            },
        }));

        window.DATAPOOL_i18n_CATALOGS = {
            ...window.DATAPOOL_i18n_CATALOGS,
            [langCode]: catalog,
        };
    }

    getNewPath() {
        return this.props.user === null ? '/login' : '/app';
    }

    render() {
        const { language, catalogs, isFetchingParams } = this.state;

        if (isFetchingParams) {
            return null;
        }

        // console.warn(getBasePathFromLocation(window.location));

        const newPath = this.getNewPath();

        return (
            <div>
                <I18nProvider language={language} catalogs={catalogs}>
                    <Router history={history}>
                        <Switch>
                            <Redirect from="/" exact={true} to={newPath} />
                            <Route path="/login_external" component={ExternalLoginRedirector} />
                            <Route path="/app" component={App} />
                            <Route path="/login" component={Login} />
                            <Route path="/signup" component={Signup} />
                            <Route path="/password-lost" component={PasswordLost} />
                            <Route path="/reset-password" component={ResetPassword} />
                            <Route
                                path="/public/article/:brand/:article"
                                component={ArticlePublic}
                            />
                            <Route component={ErrorPage} />
                        </Switch>
                    </Router>
                    <ToastContainer autoClose={10000} closeButton={<CloseButton />} />
                </I18nProvider>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        user: selectors.getUser(state),
        language: appSelectors.getLanguage(state),
    };
}

export default connect(mapStateToProps)(Root);
