import React, { useEffect, useState, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { withI18n, Trans, i18nMark } from '@lingui/react';
import { useQuery } from 'react-query';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { FaRedo } from 'react-icons/fa';
import { DataTable } from '@ingruz/tabulisk';
import queryString from 'query-string';
import clsx from 'classnames';
import find from 'lodash/find';
// import sortBy from 'lodash/sortBy';
import isObject from 'lodash/isObject';
import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';

import { getAvailableExchangeFormats, getFilteredExports, startExport } from '../api';
import { selectors } from '../reducers/filtersReducer';
import { selectors as appSelectors } from '../reducers/appReducer';
import SubmitButton from './form/SubmitButton';
import SelectField from './form/SelectField';
import InputField from './form/InputField';
import TextareaField from './form/TextareaField';
import Loader from './utils/Loader';
import { SelectBrandsMultipleField } from './form/SelectBrandsMultiple';
import ActiveFiltersTagsList from './ActiveFiltersTagsList';
import ExportHeaderModal from './ExportHeaderModal';
import parse from 'date-fns/parse';
import differenceInDays from 'date-fns/difference_in_days';

function getStatusLabel(status) {
    switch (status) {
        case 'ended':
            return 'Completata';
        case 'running':
            return 'In corso';
        case 'starting':
            return 'In attesa';
        default:
            return '-';
    }
}

function validate(values, type) {
    const mandatoryFieldError = i18nMark('validation:error:mandatory:field');
    let errors = {};

    // console.log(values);

    if (!values.exchange_format) {
        errors.exchange_format = mandatoryFieldError;
    }

    if (type === 'Marca') {
        if (!values.brands || values.brands.length === 0) {
            errors.brands = mandatoryFieldError;
        }
    }

    console.log(errors);

    return errors;
}

function ExportsPanel(props) {
    const qs = queryString.parse(props.location.search);

    const [exportType, setExportType] = useState(qs.type || 'Marca');
    const [showHeaderModal, setShowHeaderModal] = useState(false);
    const [availableExchangeFormats, setAvailableExchangeFormats] = useState([]);
    const [selectedExchangeFormat, setSelectedExchangeFormat] = useState(null);
    let formRef = useRef(null);

    // console.log(exportType);

    // console.log(props);

    const {
        // isLoading,
        isFetching,
        data: exportsData,
        refetch,
    } = useQuery(['filteredExports', {}], getFilteredExports, { refetchOnWindowFocus: false });

    useEffect(async () => {
        const exchangeFormatsRes = await getAvailableExchangeFormats();

        setAvailableExchangeFormats(exchangeFormatsRes.data);
    }, []);

    const onSubmit = async (data) => {
        // console.log(availableExchangeFormats);
        // console.log(data);

        const currentExchangeFormat = find(availableExchangeFormats, {
            code: data.exchange_format.value,
        });

        if (currentExchangeFormat.header.length > 0) {
            setShowHeaderModal(true);
            setSelectedExchangeFormat(currentExchangeFormat);
        } else {
            await onSubmitProper(data);
        }
    };

    const onSubmitProper = async (data, onlyHeader = false) => {
        let payload = data;

        let filter = {};

        // console.log(formRef.current);

        if (exportType === 'Marca') {
            const baseFormState = formRef.current.getState();

            filter = {
                sigle_marca: baseFormState.values.brands,
            };
        } else if (exportType === 'Selezione') {
            filter = omit(props.apiPayload, [
                'locale',
                'page',
                'per_page',
                'include_ricambi',
                'include_accessori',
                'sort',
                'sort_direction',
            ]);
        }

        if (filter && filter.sigle_marca && filter.sigle_marca.length > 1) {
            if (selectedExchangeFormat.multimarca === false) {
                toast(
                    `Il formato selezionato (${selectedExchangeFormat.name}) non supporta l'export di più marche. Selezionarne uno con la dicitura (multimarca) e riprovare!`,
                    {
                        position: 'bottom-right',
                        type: toast.TYPE.ERROR,
                    }
                );
            }

            return;
        }

        if (onlyHeader) {
            payload = {
                code: selectedExchangeFormat.code,
                filter,
                header: mapValues(data, (v) => {
                    return isObject(v) ? v.value : v;
                }),
            };
        }

        await startExport(payload);

        toast('Richiesta di export effettuata correttamente', {
            position: 'bottom-right',
            type: toast.TYPE.SUCCESS,
            autoClose: 10000,
        });

        formRef.current.reset();

        refetch();
    };

    const columns = React.useMemo(() => {
        return [
            {
                accessor: 'occurred_at',
                Header: <Trans id="request time" />,
            },
            {
                accessor: 'info.name_channel',
                Header: <Trans id="exchange format" />,
            },
            {
                accessor: 'asset',
                Header: <Trans id="asset" />,
            },
            {
                accessor: 'note',
                Header: <Trans id="note" />,
                disableSortBy: true,
            },
            {
                accessor: 'status',
                Header: <Trans id="status" />,
                Cell: ({ value }) => getStatusLabel(value),
            },
            {
                accessor: 'download',
                Header: <Trans id="download" />,
                disableSortBy: true,
                Cell: ({ row }) => {
                    if (row.original.output) {
                        return (
                            <a href={row.original.output} target="_parent">
                                <Trans id="download" />
                            </a>
                        );
                    }

                    return null;
                },
            },
        ];
    }, []);

    const initialSortBy = useMemo(() => {
        return [
            {
                id: 'occurred_at',
                desc: true,
            },
        ];
    }, []);

    return (
        <div className="grid grid-cols-12 mx-2">
            {showHeaderModal && (
                <ExportHeaderModal
                    onClose={() => setShowHeaderModal(false)}
                    exchangeFormat={selectedExchangeFormat}
                    onSubmit={onSubmitProper}
                />
            )}
            <div className="col-span-12 text-center mt-2">
                <h2 className="text-primary">Download</h2>
            </div>
            <div className="col-span-12 xl:col-span-6 xl:col-start-4">
                <div className="grid grid-cols-3 gap-2">
                    <div
                        className={clsx('text-center border py-3 cursor-pointer hover:font-bold ', {
                            'font-bold border-primary bg-primary !text-white':
                                exportType === 'Marca',
                            'text-primary': exportType !== 'Marca',
                        })}
                        onClick={() => setExportType('Marca')}
                    >
                        <Trans id="export brands" />
                    </div>
                    <div
                        className={clsx(
                            'text-center border py-3 cursor-not-allowed hover:font-bold',
                            {
                                'font-bold border-primary bg-primary !text-white':
                                    exportType === 'Collezione',
                                'text-primary': exportType !== 'Collezione',
                            }
                        )}
                        // onClick={() => setExportType('Collezione')}
                    >
                        <Trans id="export lists" />
                    </div>
                    <div
                        className={clsx('text-center border py-3 cursor-pointer hover:font-bold', {
                            'font-bold border-primary bg-primary !text-white':
                                exportType === 'Selezione',
                            'text-primary': exportType !== 'Selezione',
                            'cursor-not-allowed': props.isAnyFilterActive === false,
                        })}
                        onClick={() => (props.isAnyFilterActive ? setExportType('Selezione') : {})}
                    >
                        <Trans id="export selection" />
                    </div>
                </div>
                {exportType === 'Selezione' && (
                    <div className="bg-gray p-2 min-h-8 mt-2 flex">
                        <b className="text-primary mr-4 capitalize">
                            <Trans id="active filters" />
                        </b>
                        <ActiveFiltersTagsList showClearBtn={false} />
                    </div>
                )}
                {exportType && (
                    <div>
                        <Form
                            onSubmit={onSubmit}
                            validate={(values) => validate(values, exportType)}
                            // initialValues={this.state}
                            render={({
                                handleSubmit,
                                pristine,
                                hasValidationErrors,
                                submitError,
                                submitting,
                                errors,
                                touched,
                                form,
                                ...rest
                            }) => {
                                if (form) {
                                    formRef.current = form;
                                }

                                return (
                                    <form
                                        // ref={formRef}
                                        onSubmit={handleSubmit}
                                        className="form-vertical text-primary"
                                    >
                                        <div className="grid grid-cols-2 gap-2 mb-2 mt-4">
                                            {exportType === 'Marca' && (
                                                <div className="col-span-2 xl:col-span-1">
                                                    <Field
                                                        name="brands"
                                                        component={SelectBrandsMultipleField}
                                                        valueKey="code"
                                                    />
                                                </div>
                                            )}
                                            {exportType === 'Collezione' && (
                                                <div className="col-span-2 xl:col-span-1"></div>
                                            )}
                                            <div
                                                className={
                                                    exportType !== 'Selezione'
                                                        ? 'col-span-2 xl:col-span-1'
                                                        : 'col-span-2'
                                                }
                                            >
                                                <Field
                                                    name="exchange_format"
                                                    component={SelectField}
                                                    options={availableExchangeFormats.map((e) => {
                                                        let label = e.name;

                                                        if (e.multimarca) {
                                                            label += ' (multimarca)';
                                                        }

                                                        return {
                                                            value: e.code,
                                                            label,
                                                        };
                                                    })}
                                                    layout="vertical"
                                                    placeholder={i18nMark('select exchange format')}
                                                    className="form-input input-lg"
                                                />
                                            </div>
                                        </div>
                                        <div className="mb-2">
                                            <Field
                                                name="notification_email"
                                                component={InputField}
                                                layout="vertical"
                                                placeholder={i18nMark('mail notify download ready')}
                                                className="form-input input-lg"
                                            />
                                        </div>
                                        <div className="mb-4">
                                            <Field
                                                name="note"
                                                component={TextareaField}
                                                layout="vertical"
                                                placeholder={i18nMark('note')}
                                                className="form-input input-lg"
                                            />
                                        </div>
                                        <SubmitButton
                                            submitting={submitting}
                                            disabled={
                                                pristine ||
                                                (exportType === 'Selezione' &&
                                                    !props.isAnyFilterActive)
                                            }
                                        >
                                            <Trans id="request" />
                                        </SubmitButton>
                                    </form>
                                );
                            }}
                        />
                    </div>
                )}
            </div>
            <div className="col-span-12 mt-12">
                <div className="text-primary text-xl">
                    <Trans id="request status" />
                </div>
                <div className="flex items-center mb-1">
                    <button
                        className="btn btn-link mr-0.5"
                        onClick={refetch}
                        disabled={exportType === null}
                    >
                        <FaRedo />
                    </button>
                    <span className="text-primary">
                        <Trans id="click the icon to refresh" />
                    </span>
                </div>
                {exportType !== null && (
                    <DataTable
                        data={
                            isFetching
                                ? []
                                : exportsData.data.results.filter((row) => {
                                      if (row.type === null || row.type !== exportType) {
                                          return false;
                                      }

                                      const date = parse(
                                          row.occurred_at,
                                          'yyyy-MM-dd HH:mm:ss',
                                          new Date()
                                      );

                                      const diff = differenceInDays(new Date(), date);

                                      return diff <= props.exportsListDaysLimit || 30;
                                  })
                        }
                        columns={columns}
                        config={{
                            useFlex: false,
                            remote: false,
                            paginate: false,
                            noRowsMessage: isFetching ? (
                                <Loader />
                            ) : (
                                <div className="bg-gray py-2 text-center">
                                    <Trans
                                        id="no export in the last n days"
                                        values={{ count: props.exportsListDaysLimit }}
                                    />
                                </div>
                            ),
                            classNames: {
                                tableClassName: 'table table-striped table-hover table-dense',
                            },
                            initialSortBy,
                        }}
                    />
                )}
                {/* <table className="table table-striped table-hover table-dense">
                    <thead>
                        <tr>
                            <th>
                                <Trans id="selection" />
                            </th>
                            <th>
                                <Trans id="request time" />
                            </th>
                            <th>
                                <Trans id="exchange format" />
                            </th>
                            <th>
                                <Trans id="asset" />
                            </th>
                            <th>
                                <Trans id="note" />
                            </th>
                            <th>
                                <Trans id="status" />
                            </th>
                            <th>
                                <Trans id="download" />
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ? (
                            <tr>
                                <td colSpan="999">
                                    <Loader />
                                </td>
                            </tr>
                        ) : (
                            sortBy(exportsData.data.results, sortKey)
                                .reverse()
                                .map((e) => {
                                    return (
                                        <tr key={e.id}>
                                            <td></td>
                                            <td>{e.occurred_at}</td>
                                            <td>{e.info.name_channel}</td>
                                            <td>-</td>
                                            <td>-</td>
                                            <td>{getStatusLabel(e.status)}</td>
                                            <td>
                                                {e.output && (
                                                    <a href={e.output} target="_parent">
                                                        <Trans id="download" />
                                                    </a>
                                                )}
                                            </td>
                                        </tr>
                                    );
                                })
                        )}
                    </tbody>
                </table> */}
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        apiPayload: selectors.getApiPayload(state),
        isAnyFilterActive: selectors.isAnyFilterActive(state),
        exportsListDaysLimit: appSelectors.getExportsListDaysLimit(state),
    };
}

const ConnectedExportsPanel = connect(mapStateToProps)(ExportsPanel);

const RouterConnectedExportsPanel = withRouter(ConnectedExportsPanel);

export default withI18n()(RouterConnectedExportsPanel);
