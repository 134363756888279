import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withI18n, Trans } from '@lingui/react';
import { FaFileExport } from 'react-icons/fa';
// import _ from 'lodash';

import Loader from './utils/Loader';
import ErrorBoundary from './utils/ErrorBoundary';
// import { actions as productClassesActions } from '../actions/productClassesActions';
import ProductClassAttributeFilters from './ProductClassAttributeFilters';
// import DropdownListFilterWrapper from './utils/DropdownListFilterWrapper';
import { changeBrandFilterValue, setFilterValue } from '../actions/filtersActions';
import { setProductClassValue } from '../actions/productClassesActions';
import { toggleTreeItem } from '../actions/sectorsActions';
// import { getLocaleFromLanguageCode } from '../intl-helpers';
import * as c from '../constants';
import ActiveFiltersTagsList from './ActiveFiltersTagsList';
import { NavLink } from 'react-router-dom';
// import noPhotoImg from '../images/no-photo.png';

export class ProductClassFilter extends Component {
    state = {
        showFilters: true,
        lastSearchHash: null,
    };

    toggleFilters = () => {
        this.setState((prevState) => ({
            showFilters: !prevState.showFilters,
        }));
    };

    // toggleImage = () => {
    //     this.setState(prevState => ({
    //         showImage: !prevState.showImage
    //     }));
    // };

    handleChangeBooleanField = (key, value) => {
        this.props.onSetProductClassAttributeLogic(key, value);
    };

    handleChangeNumberField = (key, values) => {
        this.props.onSetProductClassAttributeNumeric(key, values);
    };

    // handleFilterClick = () => {
    //     this.props.fetchItems();

    //     this.setState({
    //         lastSearchHash: this.props.activeFilters.hashCode()
    //     });
    // };

    handleResetClick = () => {
        this.props.resetProductClassAttributeAll();

        // this.setState({
        //     lastSearchHash: null
        // });
    };

    // isDisabledFilterBtn() {
    //     if (
    //         this.state.lastSearchHash === null &&
    //         this.props.activeFilters.count() === 0
    //     ) {
    //         return true;
    //     }

    //     return (
    //         this.state.lastSearchHash === this.props.activeFilters.hashCode()
    //     );
    // }

    isDisabledResetBtn() {
        // if (this.state.lastSearchHash === null) {
        //     return true;
        // }

        return this.props.activeFilters.count() === 0;
    }

    handleRemoveFilter(key, value) {
        if (key === 'sector') {
            this.props.dispatch(toggleTreeItem(value));
        }

        if (key === c.BRAND_FILTER) {
            this.props.dispatch(changeBrandFilterValue({ value }));

            return;
        }

        if (key === c.PRICE_RANGE_FILTER) {
            this.props.dispatch(setFilterValue(key, [0, 0]));

            return;
        }

        if (key === c.FULL_TEXT_FILTER) {
            this.props.dispatch(setFilterValue(key, ''));

            return;
        }

        if (key === 'productClass') {
            this.props.dispatch(setProductClassValue(null));

            return;
        }

        if (key === c.REPLACEMENTS_FILTER || key === c.OPTIONALS_FILTER) {
            this.props.dispatch(setFilterValue(key, false));

            return;
        }

        if (key === c.FUORI_CARTACEO_FILTER) {
            this.props.dispatch(setFilterValue(key, null));

            return;
        }

        this.props.dispatch(setFilterValue(key, { value }));
    }

    render() {
        const {
            // i18n,
            isFetching,
            productClassFilter,
            activeFilters,
            attributes,
            onCheckboxToggle,
            onAttributeResetClick,
            fetchProductClassAttributeData,
            enableExportPanel,
            // globalActiveFilters,
            language,
            // validFilterOptions,
        } = this.props;

        // TODO: usare isAnyFilterActive per sapere se mostrare o meno link a export selezione

        // console.log(this.props.activeFilters.count());

        // console.log(this.props);

        // console.log(globalActiveFilters);

        const { showFilters /*, showImage*/ } = this.state;

        const hasAttributes = attributes.size > 0;

        const active = productClassFilter.value !== null;
        const showLoader = active && isFetching;

        if (this.props.location.pathname === '/app/lists') {
            return null;
        }

        return (
            <>
                <div className="bg-gray p-2 grid grid-cols-12 pt-4 gap-1">
                    <div className="col-span-10 lg:col-span-8 order-1 lg:order-1">
                        <ActiveFiltersTagsList
                            handleRemoveFilter={this.handleRemoveFilter.bind(this)}
                            onChangeBooleanField={this.handleChangeBooleanField.bind(this)}
                            onAttributeResetClick={onAttributeResetClick}
                            onCheckboxToggle={onCheckboxToggle}
                        />
                    </div>
                    <div className="col-span-2 lg:col-span-4 text-right order-3 lg:order-2">
                        <div className="form-group">
                            {hasAttributes && (
                                <label className="form-switch" style={{ display: 'inline-block' }}>
                                    <input
                                        disabled={!active}
                                        type="checkbox"
                                        checked={showFilters}
                                        onChange={this.toggleFilters}
                                    />
                                    <i className="form-icon" /> <Trans id="Show filters ETIM" />
                                </label>
                            )}
                            {/* FIXME: In caso di monomarca il filtro della marca é sempre attivo quindi il tasto risulta cliccabile... */}
                            {enableExportPanel && this.props.isAnyFilterActive ? (
                                <NavLink
                                    to="/app/exports?type=Selezione"
                                    className="btn btn-secondary relative ml-2 tooltip tooltip-left cursor-pointer"
                                    data-tooltip="Esporta selezione"
                                >
                                    <FaFileExport style={{ marginTop: '4px' }} />
                                </NavLink>
                            ) : (
                                <button
                                    disabled={true}
                                    className="btn btn-secondary relative ml-2 tooltip tooltip-left cursor-not-allowed"
                                    data-tooltip="Esporta selezione"
                                >
                                    <FaFileExport style={{ marginTop: '4px' }} />
                                </button>
                            )}
                            {/* <label className="form-switch">
                            <input
                                disabled={!active}
                                type="checkbox"
                                checked={showImage}
                                onChange={this.toggleImage}
                            />
                            <i className="form-icon" /> Mostra
                            immagine
                        </label> */}
                        </div>
                        {/* {this.state.showImage &&
                        !showLoader &&
                        active && (
                            <img
                                src={noPhotoImg}
                                className="img-responsive float-right"
                                alt="Classe prodotto"
                            />
                        )}*/}
                    </div>
                    {showLoader && (
                        <div className="text-center col-span-12">
                            <Loader height={100} />
                        </div>
                    )}
                    <ErrorBoundary>
                        {this.state.showFilters && (
                            <>
                                {!showLoader && active && (
                                    <Fragment>
                                        <div className="col-span-6 order-2 lg:order-3 self-center lg:col-span-12 text-right lg:!text-center lg:mb-2">
                                            {hasAttributes && (
                                                <Fragment>
                                                    {/* <button
                                                        className="btn btn-sm btn-primary btn-rounded"
                                                        disabled={this.isDisabledFilterBtn()}
                                                        onClick={this.handleFilterClick}
                                                    >
                                                        <Trans id="apply:filter" />
                                                    </button>{' '} */}
                                                    <button
                                                        className="btn btn-sm btn-primary btn-rounded"
                                                        disabled={this.isDisabledResetBtn()}
                                                        onClick={this.handleResetClick}
                                                    >
                                                        <Trans id="Reset filters ETIM" />
                                                    </button>
                                                </Fragment>
                                            )}
                                            {!hasAttributes && (
                                                <div className="empty empty-short">
                                                    <p className="empty-title h5">
                                                        <Trans id="No feature present" />
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </Fragment>
                                )}
                            </>
                        )}
                    </ErrorBoundary>
                </div>
                {this.state.showFilters && (
                    <div className="bg-gray p-2 grid grid-cols-12 pt-4 gap-1">
                        <ProductClassAttributeFilters
                            etimClassId={
                                productClassFilter.value ? productClassFilter.value.value : null
                            }
                            activeFilters={activeFilters}
                            attributes={attributes}
                            language={language}
                            onCheckboxToggle={onCheckboxToggle}
                            onAttributeResetClick={onAttributeResetClick}
                            onChangeBooleanField={this.handleChangeBooleanField}
                            onChangeNumberField={this.handleChangeNumberField}
                            onFetchProductClassAttributeData={fetchProductClassAttributeData}
                            mainLoaderShowing={showLoader}
                        />
                    </div>
                )}
            </>
        );
    }
}

ProductClassFilter.propTypes = {
    attributes: PropTypes.object,
    activeFilters: PropTypes.object,
    i18n: PropTypes.object,
    language: PropTypes.string,
    productClassFilter: PropTypes.object,
    onAttributeResetClick: PropTypes.func.isRequired,
    onCheckboxToggle: PropTypes.func.isRequired,
    resetProductClassAttributeAll: PropTypes.func.isRequired,
    fetchItems: PropTypes.func.isRequired,
    fetchProductClassAttributeData: PropTypes.func.isRequired,
    validFilterOptions: PropTypes.object,
};

const ProductClassFilterIntl = withI18n()(ProductClassFilter);

export default withRouter(ProductClassFilterIntl);

/*<span key={option.value} style={{ marginRight: '5px' }}>
    <span>{option.label.it_IT}</span>{' '}
    <i
        onClick={() =>
            this.handleRemoveFilter(key, option.value)
        }
        className="form-icon icon icon-cross c-hand"
        style={{
            position: 'relative',
            top: '-2px'
        }}
    ></i>
</span>*/
