import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { Trans, i18nMark, withI18n } from '@lingui/react';
import { Link, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import omit from 'lodash/omit';

import MatchWhenGuest from './components/routing/MatchWhenGuest';
import InputField from './components/form/InputField';
import SelectProvinceField from './components/form/SelectProvinceField';
import SelectTitoliField from './components/form/SelectTitoliField';
import SelectAttivitaField from './components/form/SelectAttivitaField';
import SubmitButton from './components/form/SubmitButton';
import AppLogo from './components/utils/AppLogo';
import { logAnalytic, signup } from './api';
import { validateEmail } from './form-helpers';
import SelectLanguageField from './components/form/SelectLanguageField';

function validate(values) {
    const mandatoryFieldError = i18nMark('validation:error:mandatory:field');
    const errors = {};

    if (!values.email) {
        errors.email = mandatoryFieldError;
    } else if (!validateEmail(values.email)) {
        errors.email = i18nMark('validation:error:email:invalid');
    }

    if (!values.password) {
        errors.password = mandatoryFieldError;
    }

    if (!values.password_confirm) {
        errors.password_confirm = mandatoryFieldError;
    }

    if (values.password !== values.password_confirm) {
        errors.password_confirm = i18nMark('validation:error:password:confirm:mismatch');
    }

    if (!values.nome) {
        errors.nome = mandatoryFieldError;
    }

    if (!values.cognome) {
        errors.cognome = mandatoryFieldError;
    }

    if (!values.provincia) {
        errors.provincia = mandatoryFieldError;
    }

    if (!values.attivita) {
        errors.attivita = mandatoryFieldError;
    }

    if (!values.locale) {
        errors.locale = mandatoryFieldError;
    }

    return errors;
}

export class Signup extends Component {
    componentDidMount() {
        setTimeout(() => {
            this.form.elements[0].focus();
        }, 0);
    }

    onSubmit = async (data) => {
        const { i18n } = this.props;
        // TODO: spostare tutta questa logica in una saga?

        let payload = {
            ...omit(data, ['provincia', 'titolo', 'attivita', 'locale']),
            sigla_provincia: data.provincia.value,
            nome_provincia: data.provincia.label,
            locale: data.locale.value,
        };

        if (data.titolo) {
            payload.titolo = data.titolo.value;
        }

        if (data.attivita) {
            payload.attivita = data.attivita.value;
        }

        try {
            await signup(payload);

            try {
                logAnalytic({
                    utente: `${data.nome} ${data.cognome}`,
                    operation: 'Registrazione utente',
                });
            } catch (err) {}

            toast(i18n._('user:register:success'), {
                position: 'bottom-right',
                type: toast.TYPE.SUCCESS,
            });

            this.props.history.push('/login');
        } catch (err) {
            // console.error(err);
            let errorMessage = i18n._('error:unexpected');

            if (err.response) {
                // console.log(err.response.data);
                // console.log(err.response.status);
                if (err.response.status === 412) {
                    errorMessage = i18n._('fill:required:fields');
                } else if (err.response.status === 409) {
                    errorMessage = i18n._('user:already:registered');
                }
            } else if (err.request) {
                errorMessage = i18n._('error:server:generic');
            }

            return {
                [FORM_ERROR]: errorMessage,
            };
        }
    };

    render() {
        return (
            <div className="bg-primary login-wrapper">
                <div className="mx-2 grid grid-cols-12">
                    <div className="col-span-12 lg:col-span-6 lg:col-start-4">
                        <Form
                            onSubmit={this.onSubmit}
                            validate={validate}
                            render={({
                                handleSubmit,
                                pristine,
                                hasValidationErrors,
                                submitError,
                                submitting,
                                errors,
                                touched,
                                ...rest
                            }) => {
                                // console.warn(rest);

                                return (
                                    <form
                                        ref={(form) => (this.form = form)}
                                        onSubmit={handleSubmit}
                                        className="form-spaced form-horizontal text-dark"
                                    >
                                        <div
                                            className="text-center"
                                            style={{ marginBottom: '16px' }}
                                        >
                                            <AppLogo style={{ margin: 'auto' }} />
                                            <h3 className="text-primary">
                                                <Trans id="do:register" />
                                            </h3>
                                            <p className="text-dark">
                                                <Trans id="register:wait:confirm:message" />
                                            </p>
                                        </div>
                                        <div className="">
                                            <div className="mb-2">
                                                <Field
                                                    name="titolo"
                                                    component={SelectTitoliField}
                                                    layout="horizontal"
                                                    placeholder={i18nMark('title')}
                                                    className={'form-input input-lg'}
                                                />
                                            </div>
                                            <div className="mb-2">
                                                <Field
                                                    name="nome"
                                                    component={InputField}
                                                    layout="horizontal"
                                                    placeholder={i18nMark('name')}
                                                    mandatory={true}
                                                    className="form-input input-lg"
                                                />
                                            </div>
                                            <div className="mb-2">
                                                <Field
                                                    name="cognome"
                                                    component={InputField}
                                                    layout="horizontal"
                                                    placeholder={i18nMark('surname')}
                                                    mandatory={true}
                                                    className="form-input input-lg"
                                                />
                                            </div>
                                            <div className="mb-2">
                                                <Field
                                                    name="email"
                                                    component={InputField}
                                                    placeholder={i18nMark('email')}
                                                    mandatory={true}
                                                    layout="horizontal"
                                                    className="form-input input-lg"
                                                />
                                            </div>
                                            <div className="mb-2">
                                                <Field
                                                    name="password"
                                                    component={InputField}
                                                    layout="horizontal"
                                                    placeholder={i18nMark('password')}
                                                    mandatory={true}
                                                    inputType="password"
                                                    className="form-input input-lg"
                                                />
                                            </div>
                                            <div className="mb-2">
                                                <Field
                                                    name="password_confirm"
                                                    component={InputField}
                                                    layout="horizontal"
                                                    placeholder={i18nMark('password:confirm')}
                                                    mandatory={true}
                                                    inputType="password"
                                                    className="form-input input-lg"
                                                />
                                            </div>
                                            <div className="mb-2">
                                                <Field
                                                    name="ragione_sociale"
                                                    component={InputField}
                                                    placeholder={i18nMark('company')}
                                                    layout="horizontal"
                                                    className="form-input input-lg"
                                                />
                                            </div>
                                            <div className="mb-2">
                                                <Field
                                                    name="locale"
                                                    component={SelectLanguageField}
                                                    placeholder={i18nMark('language')}
                                                    mandatory={true}
                                                    layout="horizontal"
                                                    className="form-input input-lg"
                                                />
                                            </div>
                                            <div className="mb-2">
                                                <Field
                                                    name="attivita"
                                                    component={SelectAttivitaField}
                                                    layout="horizontal"
                                                    placeholder={i18nMark('activity')}
                                                    mandatory={true}
                                                    className={'form-input input-lg'}
                                                />
                                            </div>
                                            <div className="mb-2">
                                                <Field
                                                    name="provincia"
                                                    component={SelectProvinceField}
                                                    layout="horizontal"
                                                    placeholder={i18nMark('province')}
                                                    mandatory={true}
                                                    className={'form-input input-lg'}
                                                />
                                            </div>
                                        </div>
                                        <SubmitButton submitting={submitting} disabled={pristine}>
                                            <Trans id="register" />
                                        </SubmitButton>
                                        {submitError && (
                                            <div
                                                className="bg-error mt-2 p-2 text-center"
                                                style={{
                                                    whiteSpace: 'pre',
                                                }}
                                            >
                                                {submitError}
                                            </div>
                                        )}
                                    </form>
                                );
                            }}
                        />
                    </div>
                    <div className="col-span-12 lg:col-span-6 lg:col-start-4 my-2">
                        <Link to="/login" style={{ color: '#fff' }}>
                            <i className="icon icon-back" /> <Trans id="back:to:login" />
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

const SignupIntl = withI18n()(Signup);

export default withRouter(connect()(MatchWhenGuest(SignupIntl)));
