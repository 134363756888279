import { actionTypes } from '../actions/appActions';
import { Record, fromJS, Map, List } from 'immutable';

import * as constants from '../constants';
// import transform from 'lodash/transform';

export const AppRecord = Record({
    isBootstrapping: true,
    isLoadingSplashScreenHidden: false,
    isBootstrapFailed: false,
    isSidebarOpen: true,
    fixedBrand: false,
    // productInfoMap: {},
    productProperties: Map({}),
    userGroups: Map({}),
    language: 'it',
    defaultTableView: constants.VIEW_TYPE_COMPACT,
    currentTableView: null,
    appName: 'ilDataPool',
    tableColumns: List([]),
    schedaProdottoLogTimeout: 60,
    exportsListDaysLimit: 30,
    showLists: false,
    enableExportPanel: false,
});

export const DEFAULT_STATE = AppRecord();

function fetchStart(state, action) {
    return state.set('isBootstrapping', true);
}

function fetchSuccess(state, action) {
    return state.set('isBootstrapping', false);
}

function fetchFail(state, action) {
    return state.set('isBootstrapping', false).set('isBootstrapFailed', true);
}

function hideLoadingSplashScreen(state) {
    return state.set('isLoadingSplashScreenHidden', true);
}

// function setProductInfoMap(state, action) {
//     let infoMap = action.payload;

//     infoMap.confezionamenti = infoMap.packaging;

//     return state.set('productInfoMap', infoMap);
// }

function setFixedBrand(state) {
    return state.set('fixedBrand', true);
}

function changeLanguage(state, action) {
    return state.set('language', action.payload);
}

function setProductProperties(state, action) {
    const propertiesObj = action.payload.reduce((obj, { id, code, label, type }) => {
        obj[code] = {
            id,
            code,
            label,
            type,
        };

        return obj;
    }, {});

    return state.set('productProperties', fromJS(propertiesObj));
}

function setAppName(state, action) {
    return state.set('appName', action.payload);
}

function changeDefaultTableView(state, action) {
    return state.set('defaultTableView', action.payload);
}

function setUserGroups(state, action) {
    return state.set(
        'userGroups',
        fromJS(
            action.payload.data.reduce((res, group) => {
                res[group.id] = group;

                return res;
            }, {})
        )
    );
}

function addUserGroup(state, action) {
    return state.updateIn(['userGroups'], (groups) => {
        return groups.set(action.payload.id, action.payload.data);
    });
}

function updateUserGroup(state, action) {
    return state.updateIn(['userGroups'], (groups) => {
        return groups.set(action.payload.id, action.payload.data);
    });
}

function setTableColumns(state, action) {
    return state.set('tableColumns', fromJS(action.payload.columns));
}

function changeCurrentTableView(state, action) {
    return state.set('currentTableView', action.payload);
}

function toggleSidebar(state) {
    return state.set('isSidebarOpen', !state.get('isSidebarOpen'));
}

function setSchedaProdottoLogTimeout(state, action) {
    return state.set('schedaProdottoLogTimeout', action.payload);
}

function handleSetAppParameter(state, action) {
    return state.set(action.payload.key, action.payload.value);
}

// function setProductInfoMap(state, action) {
//     return state.set(
//         'productInfoMap',
//         transform(
//             action.payload,
//             (res, v, k) => {
//                 res[k] = {
//                     path: k,
//                     properties: transform(v, (props, value, key) => {
//                         props[key] = {
//                             ...value,
//                             path: `${k}.${key}`,
//                             property_code: key
//                         };
//                     })
//                 };

//                 return res;
//             },
//             {}
//         )
//     );
// }

const handlers = {
    [actionTypes.BOOTSTRAP_APP_START]: fetchStart,
    [actionTypes.BOOTSTRAP_APP_SUCCESS]: fetchSuccess,
    [actionTypes.BOOTSTRAP_APP_FAIL]: fetchFail,
    // [actionTypes.SET_PRODUCT_INFO_MAP]: setProductInfoMap,
    [actionTypes.HIDE_LOADING_SPLASH_SCREEN]: hideLoadingSplashScreen,
    [actionTypes.SET_FIXED_BRAND]: setFixedBrand,
    [actionTypes.CHANGE_LANGUAGE]: changeLanguage,
    [actionTypes.SET_PRODUCT_PROPERTIES]: setProductProperties,
    [actionTypes.SET_APP_NAME]: setAppName,
    [actionTypes.CHANGE_DEFAULT_TABLE_VIEW]: changeDefaultTableView,
    [actionTypes.SET_USER_GROUPS]: setUserGroups,
    [actionTypes.ADD_USER_GROUP]: addUserGroup,
    [actionTypes.UPDATE_USER_GROUP]: updateUserGroup,
    [actionTypes.SET_TABLE_COLUMNS]: setTableColumns,
    [actionTypes.CHANGE_CURRENT_TABLE_VIEW]: changeCurrentTableView,
    [actionTypes.TOGGLE_SIDEBAR]: toggleSidebar,
    [actionTypes.SET_SCHEDA_PRODOTTO_LOG_TIMEOUT]: setSchedaProdottoLogTimeout,
    [actionTypes.SET_APP_PARAMETER]: handleSetAppParameter,
};

export default function appReducer(state = DEFAULT_STATE, action) {
    if (handlers.hasOwnProperty(action.type)) {
        return handlers[action.type](state, action);
    } else {
        return state;
    }
}

export const selectors = {
    getIsBootstrapping(state) {
        return state.app.isBootstrapping;
    },
    getIsLoadingSplashScreenHidden(state) {
        return state.app.isLoadingSplashScreenHidden;
    },
    getIsBootstrapFailed(state) {
        return state.app.isBootstrapFailed;
    },
    // getProductInfoMap(state) {
    //     return state.app.productInfoMap;
    // },
    getIsBrandFixed(state) {
        return state.app.fixedBrand;
    },
    getLanguage(state) {
        return state.app.language;
    },
    getProductProperties(state) {
        return state.app.productProperties ? state.app.productProperties.toJS() : {};
    },
    getAppName(state) {
        return state.app.appName;
    },
    getDefaultTableView(state) {
        return state.app.defaultTableView;
    },
    getUserGroups(state) {
        return state.app.userGroups.toJS();
    },
    getTableColumns(state) {
        return state.app.tableColumns.toJS();
    },
    getCurrentViewType(state) {
        return (
            state.app.currentTableView || state.app.defaultTableView || constants.VIEW_TYPE_COMPACT
        );
    },
    getIsSidebarOpen(state) {
        return state.app.isSidebarOpen;
    },
    getSchedaProdottoLogTimeout(state) {
        return state.app.schedaProdottoLogTimeout;
    },
    getExportsListDaysLimit(state) {
        return state.app.exportsListDaysLimit;
    },
    getShowLists(state) {
        return state.app.showLists;
    },
    getEnableExportPanel(state) {
        return state.app.enableExportPanel;
    },
};
